<template>
    <div class="content-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card" style="min-height: 400px">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-responsive-md">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Wallet</th>
                                        <th>Amount</th>
                                        <th>Wallet Type</th>
                                        <th>Status</th>
                                        <th>Created</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <template v-if="withdrawals.length > 0">
                                        <tr v-for="(withdraw, idx) in withdrawals" :key="idx">
                                            <td>
                                                <strong>{{ idx+1 }}</strong>
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <span class="w-space-no">{{ withdraw.data.wallet }}</span>
                                                </div>
                                            </td>
                                            <td>${{withdraw.data.amount}}	</td>
                                            <td>{{withdraw.data.cryptoID}}</td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <template v-if="withdraw.data.status==='APPROVED'">
                                                        <i class="fa fa-circle text-success mr-1"></i>
                                                        {{ withdraw.data.status }}
                                                    </template>
                                                    <template v-else-if="withdraw.data.status==='PENDING'">
                                                        <i class="fa fa-circle text-warning mr-1"></i>
                                                        {{ withdraw.data.status }}
                                                    </template>
                                                    <template v-else>
                                                        <i class="fa fa-circle text-danger mr-1"></i>
                                                        {{ withdraw.data.status }}
                                                    </template>
                                                </div>
                                            </td>
                                            <td>{{getReadableDate(withdraw.data.createdAt)}}</td>
                                            <td>
                                                <div class="d-flex">
                                                    <a @click="active_withdrawal=withdraw" href="javascript:void(0)" class="btn btn-info shadow btn-xs sharp mr-1" data-toggle="modal" data-target="#viewWithdrawal">
                                                        <i class="fa fa-eye"></i>
                                                    </a>
                                                    <template v-if="withdraw.data.status==='PENDING'">
                                                        <a @click="toggleStatus(withdraw)" href="javascript:void(0)" class="btn btn-success shadow btn-xs sharp" data-toggle="tooltip" title="confirm">
                                                            <i class="fa fa-check"></i>
                                                        </a>
                                                        <a @click="toggleStatus(withdraw, false)" href="javascript:void(0)" class="btn btn-danger shadow btn-xs sharp ml-1" data-toggle="tooltip" title="decline">
                                                            <i class="fa fa-ban"></i>
                                                        </a>
                                                    </template>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td colspan="7">
                                                <div class="col-12 text-center mt-4">
                                                    <h6 class="text-info">Empty withdrawal history</h6>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <view-withdrawal :withdrawal="active_withdrawal"/>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import methodsMixin from "../../../utils/methodsMixin";
import ViewWithdrawal from "./ViewWithdrawal";

export default {
    name: "Withdrawals",
    mixins: [methodsMixin],
    data(){
        return {
            active_withdrawal: {id: '', data: {}}
        }
    },
    computed: {
        ...mapGetters('withdrawal', {
            withdrawals: 'getAllWithdrawals'
        })
    },
    methods: {
      async toggleStatus(withdrawal, accept=true){
          const status = confirm(`${accept?'Confirm':'Decline'} this request? This operation is not reversible`);
          if(status){
              const response = await this.$store.dispatch('withdrawal/toggleWithdrawalStatus', {
                  withdrawal,
                  status: accept?'APPROVED':'DECLINED'
              });
              if(response.status){
                  return toastr.success("Withdrawal confirmed")
              }else{
                  return toastr.error(response.message)
              }
          }
      }
    },
    components: {
      ViewWithdrawal
    },
    mounted() {
        this.$store.dispatch('withdrawal/fetchAllWithdrawals')
    }
}
</script>

<style scoped>

</style>