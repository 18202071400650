<template>
    <div class="modal fade" id="viewWithdrawal">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-primary">#TE-{{withdrawal.id.substr(0,10)}}</h5>
                    <button type="button" class="close" data-dismiss="modal"><span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="card contact-bx item-content">
                        <div class="card-body user-profile">
                            <div class="container-fluid px-0 pt-0">
                                <div class="row text-left">
                                    <div class="col-12">
                                        <h6>Wallet Type</h6>
                                    </div>
                                    <div class="col-12 mb-3">{{withdrawal.data.cryptoID}}</div>

                                    <div class="col-12">
                                        <h6>Wallet</h6>
                                    </div>
                                    <div class="col-12 mb-3">{{withdrawal.data.wallet}}</div>

                                    <div class="col-12">
                                        <h6>Amount</h6>
                                    </div>
                                    <div class="col-12 mb-3">${{withdrawal.data.amount.toLocaleString()}}</div>

                                    <template v-if="user_info.id">
                                        <div class="col-12">
                                            <h6>User name</h6>
                                        </div>
                                        <div class="col-12 mb-3">{{user_info.data.name}}</div>

                                        <div class="col-12">
                                            <h6>User email</h6>
                                        </div>
                                        <div class="col-12 mb-3">{{user_info.data.email}}</div>

                                        <div class="col-12">
                                            <h6>Account Balance</h6>
                                        </div>
                                        <div class="col-12 mb-3">${{user_info.data.balance.toLocaleString()}}</div>
                                    </template>

                                    <div class="col-12">
                                        <h6>Created at</h6>
                                    </div>
                                    <div class="col-12">{{getReadableDate(withdrawal.data.createdAt)}}</div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger light" data-dismiss="modal">Close</button>
<!--                    <button type="button" class="btn btn-primary">Save changes</button>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import methodsMixin from "../../../utils/methodsMixin";

export default {
    name: "ViewWithdrawal",
    data(){
      return {
          user_info: {id: '', data: {}}
      }
    },
    props: ['withdrawal'],
    mixins: [methodsMixin],
    watch: {
        withdrawal(){
            return this.getUserInfo()
        }
    },
    methods: {
        async getUserInfo(){
            this.user_info = {id: '', data: {}};
            const response = await this.$store.dispatch('user/getUser', this.withdrawal.data.user);
            if(response.status){
                this.user_info = response.data;
            }
        }
    }
}
</script>

<style scoped>

</style>